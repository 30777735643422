define("ember-cli-jstree/components/ember-jstree", ["exports", "ember-component-inbound-actions/inbound-actions", "ember-cli-jstree/mixins/ember-jstree-actions"], function (exports, _inboundActions, _emberJstreeActions) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var testing = Ember.testing;
  exports.default = Ember.Component.extend(_inboundActions.default, _emberJstreeActions.default, {
    // Properties for Ember communication
    actionReceiver: null,
    currentNode: null,
    selectedNodes: null,

    // Basic configuration objects
    data: Ember.A(),
    plugins: Ember.A(),
    themes: Ember.A(),
    checkCallback: true,
    multiple: true,
    worker: true,

    // Refresh configuration variables
    skipLoading: false,
    forgetState: false,

    // Plugin option objects
    checkboxOptions: null,
    contextmenuOptions: null,
    typesOptions: null,
    searchOptions: null,
    dndOptions: null,

    selectionDidChange: null,
    treeObject: null,

    // Internals
    _isDestroying: false,

    isReady: false,
    _searchTerm: null,

    _isReadyTestWaiter: function _isReadyTestWaiter() {
      return this.get("isReady") === true;
    },
    didInsertElement: function didInsertElement() {
      Ember.run.schedule("afterRender", this, this.createTree);
    },
    createTree: function createTree() {
      if (testing) {
        // Add test waiter.
        Ember.Test.registerWaiter(this, this._isReadyTestWaiter);
      }

      var treeObject = this._setupJsTree();

      this._setupEventHandlers(treeObject);

      this.set("treeObject", treeObject);
    },
    willDestroyElement: function willDestroyElement() {
      if (testing) {
        Ember.Test.unregisterWaiter(this, this._isReadyTestWaiter);
      }

      this.set("isReady", false);
      this.set("_isDestroying", true);
      this.send("destroy");
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var pluginsArray = this.get("plugins");
      if (Ember.isPresent(pluginsArray)) {
        var searchOptions = this.get("searchOptions");
        if (Ember.isPresent(searchOptions) && pluginsArray.indexOf("search") >= 0) {
          var searchTerm = this.get("searchTerm");
          if (this.get("_searchTerm") !== searchTerm) {
            Ember.run.next("afterRender", function () {
              _this.set("_searchTerm", searchTerm);
              _this.getTree().search(searchTerm);
            });
          }
        }
      }
    },


    /**
     * Main setup function that registers all the plugins and sets up the core
     * configuration object for jsTree
     *
     * @method _setupJsTree
     */
    _setupJsTree: function _setupJsTree() {
      return this.$().jstree(this._buildConfig());
    },


    /**
     * Builds config object for jsTree. Could be used to override config in descendant classes.
     *
     * @method _buildConfig
     */
    _buildConfig: function _buildConfig() {
      var configObject = {};

      configObject["core"] = {
        data: this.get("data"),
        check_callback: this.get("checkCallback"),
        multiple: this.get("multiple"),
        worker: this.get("worker")
      };

      var themes = this.get("themes");
      if (Ember.isPresent(themes) && Ember.typeOf(themes) === "object") {
        configObject["core"]["themes"] = themes;
      }

      var pluginsArray = this.get("plugins");
      if (Ember.isPresent(pluginsArray)) {
        pluginsArray = pluginsArray.replace(/ /g, "").split(",");
        configObject["plugins"] = pluginsArray;

        if (pluginsArray.includes("contextmenu") || pluginsArray.includes("dnd") || pluginsArray.includes("unique")) {
          // These plugins need core.check_callback
          configObject["core"]["check_callback"] = configObject["core"]["check_callback"] || true;
        }

        var checkboxOptions = this.get("checkboxOptions");
        if (Ember.isPresent(checkboxOptions) && pluginsArray.includes("checkbox")) {
          configObject["checkbox"] = checkboxOptions;
        }

        var searchOptions = this.get("searchOptions");
        if (Ember.isPresent(searchOptions) && pluginsArray.includes("search")) {
          configObject["search"] = searchOptions;
        }

        var stateOptions = this.get("stateOptions");
        if (Ember.isPresent(stateOptions) && pluginsArray.includes("state")) {
          configObject["state"] = stateOptions;
        }

        var typesOptions = this.get("typesOptions");
        if (Ember.isPresent(typesOptions) && pluginsArray.includes("types")) {
          configObject["types"] = typesOptions;
        }

        var contextmenuOptions = this.get("contextmenuOptions");
        if (Ember.isPresent(contextmenuOptions) && pluginsArray.includes("contextmenu")) {
          configObject["contextmenu"] = this._setupContextMenus(contextmenuOptions);
        }

        var dndOptions = this.get("dndOptions");
        if (Ember.isPresent(dndOptions) && pluginsArray.includes("dnd")) {
          configObject["dnd"] = dndOptions;
        }
      }

      return configObject;
    },


    /**
     * Setup context menu action handlers to handle ember actions
     *
     * @method _setupContextMenus
     * @param  {Array} contextmenuOptions Context menu configuration options
     * @return {Array} An Array of Ember-friendly options to pass back into the config object
     */
    _setupContextMenus: function _setupContextMenus(contextmenuOptions) {
      var _this2 = this;

      if (Ember.typeOf(contextmenuOptions["items"]) === "object") {
        var newMenuItems = {};
        var menuItems = Object.keys(contextmenuOptions["items"]);
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = menuItems[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var menuItem = _step.value;

            var itemData = contextmenuOptions["items"][menuItem];
            newMenuItems[menuItem] = itemData;

            // Only change if not a function
            // This needs to be done to handle Ember actions
            if (Ember.typeOf(itemData["action"]) !== "function") {
              (function () {
                var emberAction = itemData["action"];

                newMenuItems[menuItem]["action"] = function (data) {
                  _this2.send("contextmenuItemDidClick", emberAction, data);
                };
              })();
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        contextmenuOptions["items"] = newMenuItems;
      }

      return contextmenuOptions;
    },


    /**
     * Register all sorts of events
     * TODO: This should eventually encompass all of the jsTree events declared in their API.
     *
     * @method _setupEventHandlers
     * @param  {Object}
     * @return
     */
    _setupEventHandlers: function _setupEventHandlers(treeObject) {
      var _this3 = this;

      if ((typeof treeObject === "undefined" ? "undefined" : _typeof(treeObject)) !== "object") {
        throw new Error("You must pass a valid jsTree object to set up its event handlers");
      }

      /*
            Event: init.jstree
            Action: eventDidInit
            triggered after all events are bound
          */
      treeObject.on("init.jstree", function () {
        Ember.run.next(_this3, function () {
          if (this.get("isDestroyed") || this.get("isDestroying")) {
            return;
          }
          this.callAction("eventDidInit");
        });
      });

      /*
            Event: loading.jstree
            Action: eventIsLoading
            triggered after the loading text is shown and before loading starts
          */
      treeObject.on("loading.jstree", function () {
        Ember.run.next(_this3, function () {
          if (this.get("isDestroyed") || this.get("isDestroying")) {
            return;
          }
          this.callAction("eventIsLoading");
        });
      });

      /*
            Event: loaded.jstree
            Action: eventDidLoad
            triggered after the root node is loaded for the first time
          */
      treeObject.on("loaded.jstree", function () {
        Ember.run.next(_this3, function () {
          if (this.get("isDestroyed") || this.get("isDestroying")) {
            return;
          }
          this.callAction("eventDidLoad");
        });
      });

      /*
            Event: ready.jstree
            Action: eventDidBecomeReady
            triggered after all nodes are finished loading
          */
      treeObject.on("ready.jstree", function () {
        Ember.run.next(_this3, function () {
          if (this.get("isDestroyed") || this.get("isDestroying")) {
            return;
          }
          this.set("isReady", true);
          this.callAction("eventDidBecomeReady");
        });
      });

      /*
            Event: redraw.jstree
            Action: eventDidRedraw
            triggered after nodes are redrawn
          */
      treeObject.on("redraw.jstree", function () {
        Ember.run.next(_this3, function () {
          if (this.get("isDestroyed") || this.get("isDestroying")) {
            return;
          }
          this.callAction("eventDidRedraw");
        });
      });

      /*
            Event: after_open.jstree
            Action: eventDidOpen
            triggered when a node is opened and the animation is complete
          */
      treeObject.on("after_open.jstree", function (event, data) {
        Ember.run.next(_this3, function () {
          if (this.get("isDestroyed") || this.get("isDestroying")) {
            return;
          }
          this.callAction("eventDidOpen", data.node);
        });
      });

      /*
            Event: after_close.jstree
            Action: eventDidClose
            triggered when a node is closed and the animation is complete
          */
      treeObject.on("after_close.jstree", function (event, data) {
        Ember.run.next(_this3, function () {
          if (this.get("isDestroyed") || this.get("isDestroying")) {
            return;
          }
          this.callAction("eventDidClose", data.node);
        });
      });

      /*
            Event: select_node.jstree
            Action: eventDidSelectNode
            triggered when an node is selected
          */
      treeObject.on("select_node.jstree", function (event, data) {
        Ember.run.next(_this3, function () {
          if (this.get("isDestroyed") || this.get("isDestroying")) {
            return;
          }
          this.callAction("eventDidSelectNode", data.node, data.selected, data.event);
        });
      });

      /*
            Event: deselect_node.jstree
            Action: eventDidDeselectNode
            triggered when an node is deselected
          */
      treeObject.on("deselect_node.jstree", function (event, data) {
        Ember.run.next(_this3, function () {
          if (this.get("isDestroyed") || this.get("isDestroying")) {
            return;
          }
          this.callAction("eventDidDeselectNode", data.node, data.selected, data.event);
        });
      });

      /*
            Event: changed.jstree
            Action: jstreeDidChange
            triggered when selection changes
          */
      treeObject.on("changed.jstree", function (event, data) {
        Ember.run.next(_this3, function () {
          if (this.get("isDestroyed") || this.get("isDestroying")) {
            return;
          }

          // Check if selection changed
          if (Ember.isPresent(this.get("treeObject"))) {
            var selectionChangedEventNames = ["model", "select_node", "deselect_node", "select_all", "deselect_all"];
            if (Ember.isPresent(data.action) && selectionChangedEventNames.includes(data.action)) {
              var selNodes = Ember.A(this.get("treeObject").jstree(true).get_selected(true));
              this.set("selectedNodes", selNodes);
            }
          }

          this.callAction("eventDidChange", data);
        });
      });

      /*
            Event: hover_node.jstree
            Action: eventDidHoverNode
            triggered when a node is hovered
          */
      treeObject.on("hover_node.jstree", function (event, data) {
        Ember.run.next(_this3, function () {
          if (this.get("isDestroyed") || this.get("isDestroying")) {
            return;
          }
          this.callAction("eventDidHoverNode", data.node);
        });
      });

      /*
            Event: dehover_node.jstree
            Action: eventDidDehoverNode
            triggered when a node is no longer hovered
          */
      treeObject.on("dehover_node.jstree", function (event, data) {
        Ember.run.next(_this3, function () {
          if (this.get("isDestroyed") || this.get("isDestroying")) {
            return;
          }
          this.callAction("eventDidDehoverNode", data.node);
        });
      });

      /*
            Event: show_node.jstree
            Action: eventDidShowNode
            triggered when a node is no longer hovered
          */
      treeObject.on("show_node.jstree", function (event, data) {
        Ember.run.next(_this3, function () {
          if (this.get("isDestroyed") || this.get("isDestroying")) {
            return;
          }
          this.callAction("eventDidShowNode", data.node);
        });
      });

      /*
            Event: move_node.jstree
            Action: eventDidMoveNode
            triggered when a node is moved
          */
      treeObject.on("move_node.jstree", function (event, data) {
        Ember.run.next(_this3, function () {
          if (this.get("isDestroyed") || this.get("isDestroying")) {
            return;
          }
          this.callAction("eventDidMoveNode", data.node);
        });
      });

      var pluginsArray = this.get("plugins");
      if (Ember.isPresent(pluginsArray) && pluginsArray.indexOf("checkbox") > -1) {
        /*
             Event: disable_checkbox.jstree
             Action: eventDidDisableCheckbox
             triggered when an node's checkbox is disabled
           */
        treeObject.on("disable_checkbox.jstree", function (event, data) {
          Ember.run.next(_this3, function () {
            if (this.get("isDestroyed") || this.get("isDestroying")) {
              return;
            }
            this.callAction("eventDidDisableCheckbox", data.node);
          });
        });

        /*
             Event: enable_checkbox.jstree
             Action: eventDidEnableCheckbox
             triggered when an node's checkbox is enabled
           */
        treeObject.on("enable_checkbox.jstree", function (event, data) {
          Ember.run.next(_this3, function () {
            if (this.get("isDestroyed") || this.get("isDestroying")) {
              return;
            }
            this.callAction("eventDidEnableCheckbox", data.node);
          });
        });

        if (Ember.isPresent("checkboxOptions.tie_selected") && !this.get("checkboxOptions.tie_selected")) {
          /*
               Event: check_node.jstree
               Action: eventDidCheckNode
               triggered when an node is checked (only if tie_selection in checkbox settings is false)
             */
          treeObject.on("check_node.jstree", function (event, data) {
            Ember.run.next(_this3, function () {
              if (this.get("isDestroyed") || this.get("isDestroying")) {
                return;
              }
              this.callAction("eventDidCheckNode", data.node, data.selected, data.event);
            });
          });

          /*
               Event: uncheck_node.jstree
               Action: eventDidUncheckNode
               triggered when an node is unchecked (only if tie_selection in checkbox settings is false)
             */
          treeObject.on("uncheck_node.jstree", function (event, data) {
            Ember.run.next(_this3, function () {
              if (this.get("isDestroyed") || this.get("isDestroying")) {
                return;
              }
              this.callAction("eventDidUncheckNode", data.node, data.selected, data.event);
            });
          });

          /*
               Event: check_all.jstree
               Action: eventDidCheckAll
               triggered when all nodes are checked (only if tie_selection in checkbox settings is false)
             */
          treeObject.on("check_all.jstree", function (event, data) {
            Ember.run.next(_this3, function () {
              if (this.get("isDestroyed") || this.get("isDestroying")) {
                return;
              }
              this.callAction("eventDidCheckAll", data.selected);
            });
          });

          /*
               Event: uncheck_all.jstree
               Action: eventDidUncheckAll
               triggered when all nodes are unchecked (only if tie_selection in checkbox settings is false)
             */
          treeObject.on("uncheck_all.jstree", function (event, data) {
            Ember.run.next(_this3, function () {
              if (this.get("isDestroyed") || this.get("isDestroying")) {
                return;
              }
              this.callAction("eventDidUncheckAll", data.node, data.selected);
            });
          });
        }
      }
    },


    /**
     * Refreshes the data in the tree
     * TODO: Investigate why redraw(true) doesn't work...
     *
     * @method _redrawTree
     */
    _refreshTree: Ember.observer("data", function () {
      var tree = this.getTree();
      if (null !== tree && false !== tree) {
        tree.settings.core["data"] = this.get("data");
        tree.refresh(this.get("skipLoading"), this.get("forgetState"));
      } else {
        // setup again if destroyed
        var treeObject = this._setupJsTree();
        this._setupEventHandlers(treeObject);
        this.set("treeObject", treeObject);
      }
    }),

    getTree: function getTree() {
      var tree = this.get("treeObject");
      return tree.jstree(true);
    },


    actions: {
      contextmenuItemDidClick: function contextmenuItemDidClick(actionName, data) {
        var emberTreeObj = this.get("getTree");

        var instance = Ember.$.jstree.reference(data.reference);
        var node = instance.get_node(data.reference);

        this.callAction(actionName, node, emberTreeObj);
      }
    }
  });
});