define("ember-models-table/templates/components/models-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HOUjzKj6",
    "block": "{\"symbols\":[\"eachOption\",\"&default\"],\"statements\":[[4,\"each\",[[25,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[7,\"option\"],[12,\"value\",[30,[[24,1,[]]]]],[12,\"selected\",[29,\"is-equal\",[[25,[\"value\"]],[24,1,[]]],null]],[9],[1,[24,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[15,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-select.hbs"
    }
  });

  _exports.default = _default;
});